function is_user_logged_in() {
    return document.body.classList.contains('logged-in');
}

function is_webpush_available() {
    let avail = js_params.webpush_available;

    if (avail && js_params.webpush_members_only) {
        avail = is_user_logged_in();
    }

    return avail;
}

function is_service_worker_enabled() {
    return 'serviceWorker' in navigator && js_params.service_worker_enabled;
}

function get_webpush_registration_delay(_default = 6000) {
    return js_params.webpush_registration_delay * 1000 || _default;
}

function get_webpush_public_key() {
    return js_params.webpush_public_key;
}

function assetUrl(asset) {
    return `${js_params['asset_url']}/${asset}`;
}

function readCookie(cookieName) {
    const re = new RegExp('[; ]' + cookieName + '=([^\\s;]*)');
    const sMatch = (' ' + document.cookie).match(re);
    return (cookieName && sMatch) ? unescape(sMatch[1]) : false;
}

/**
 * Dynamic script loading
 *
 * @param files
 * @param after
 * @src https://gist.github.com/Aymkdn/98acfbb46fbe7c1f00cdd3c753520ea8
 */
function loadExt(files, after) {
    this.files = files;
    this.js = [];
    this.head = document.getElementsByTagName("head")[0];
    this.after = after || function () {
    };
    this.loadStyle = (file) => {
        let link = document.createElement("link");
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = file;
        this.head.appendChild(link);
    };
    this.loadScript = (i) => {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.js[i];
        const loadNextScript = () => {
            if (++i < this.js.length) this.loadScript(i);
            else this.after();
        };
        script.onload = () => {
            loadNextScript();
        };
        this.head.appendChild(script);
    };
    for (let i = 0; i < this.files.length; i++) {
        if (/\.js$|\.js\?/.test(this.files[i])) this.js.push(this.files[i]);
        if (/\.css$|\.css\?/.test(this.files[i])) this.loadStyle(this.files[i]);
    }
    if (this.js.length > 0) this.loadScript(0);
    else this.after();
}

const a4ilog = {
    base: (color, custom) => `background: ${color}; color: white; padding: 2px 0.5em;border-radius: 0.5em;${custom}`,
    log: (message) => {
        a4ilog._print(message, a4ilog.base('blue'));
    },
    error: (message) => {
        a4ilog._print(message, a4ilog.base('red'), 'error')
    },
    warn: (message) => {
        a4ilog._print(message, a4ilog.base('orange'), 'warn')
    },
    debug: (message) => {
        a4ilog._print(message, a4ilog.base('gray'), 'debug')
    },
    _print: (message, style, method = 'log') => {
        console[method]('%cartwaï', style, message);
    }
};

// Return item to store
function getPageInfo() {
    // Define item content
    let content_post = '';
    const currentpage = document.querySelector(".pagination .page-numbers.current");
    if (currentpage) {
        let lastpage = document.querySelectorAll(".pagination .page-numbers:not(.next)");
        lastpage = lastpage[lastpage.length - 1];

        content_post = currentpage.textContent + " / " + lastpage.textContent.replace("Page", "");
    }
    return {
        path: location.pathname,
        title: document.querySelector("h1").textContent.replace(/(\r\n|\n|\r)/gm, " ").replace(/\s+/g, " "),
        date_cached: pws_params.pws_date_cached,
        post_type: pws_params.pws_type_post,
        thumb: pws_params.pws_thumb_post,
        content: content_post
    }
}

class createUIPrompt {
    constructor(id, message, options) {
        this.id = id;
        this.message = message;
        this.options = options;
    }

    onAccept() {
        if ('onAccept' in this.options) {
            this.options.onAccept();
        } else {
            console.log('onAccept not provided, do nothing from this approval.');
        }
        this.dismiss();
    }

    onReject() {
        if ('onReject' in this.options) {
            this.options.onReject();
        } else {
            console.log('onReject not provided, do nothing from this refusal.');
        }
        this.dismiss();
    }

    dismiss() {
        document.getElementById(this.id).remove();
    }

    show() {
        window.addEventListener(`promptui-ok-${this.id}`, () => {
            this.onAccept();
        }, {
            once: true
        });
        window.addEventListener(`promptui-nok-${this.id}`, () => {
            this.onReject();
        }, {
            once: true
        });

        var container = document.querySelector("#wrapper"),
            firstchild = document.querySelector("#banner"),
            banner_info = document.createElement("section"),
            text_info = `${this.message} <a href="#" onclick="uipromptedok('${this.id}');"><strong>Fermer ce message.</strong></a>`;
        banner_info.setAttribute('id', this.id);
        banner_info.setAttribute('class', 'bg-9 text-center');
        banner_info.setAttribute('style', 'position:relative;z-index:10;font-size: 0.65em;');

        banner_info.innerHTML = text_info;
        container.insertBefore(banner_info, firstchild);
    }
}

function uipromptedok(id) {
    window.dispatchEvent(new CustomEvent(`promptui-ok-${id}`));
}

function uipromptednok(id) {
    window.dispatchEvent(new CustomEvent(`promptui-nok-${id}`));
}